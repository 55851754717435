import {
  Col,
  Create,
  Edit,
  Form,
  Input,
  ListButton,
  Row,
  Select,
  Switch,
  useForm,
  useSelect
} from '@pankod/refine-antd';
import { CrudFilters, usePermissions } from '@pankod/refine-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Organizations, Users } from 'utils/hasura';

import { UserRole } from 'constants/common';
import { GET_ORGANIZATION_BY_ID } from 'graphql-queries';
import { useState } from 'react';
import { GraphqlService } from 'services/graphql';
import { buildAssignableRoleOptions } from 'utils/buildAssignableRoleOptions';
import { buildPersonTitleOptions } from 'utils/buildPersonTitleOptions';

export const UserForm: React.FC = () => {
  const { t } = useTranslation();
  const { action } = useParams();
  const { data: role } = usePermissions();
  const { client: graphqlClient } = GraphqlService;
  const personTitleOptions = buildPersonTitleOptions(t);
  const assignableRoleOptions = buildAssignableRoleOptions(t, role);
  const [filteredAssignableRoleOptions, setFilteredAssignableRoleOptions] =
    useState(assignableRoleOptions);

  let userFields = [
    'id',
    'organization_id',
    'role',
    'title',
    'first_name',
    'last_name',
    'email',
    'skype',
    'mobile',
    'work_phone',
    'is_activated'
  ];

  let FormAction = Edit;
  const passwordRules = [];

  if (action === 'create') {
    userFields = [...userFields, 'password'];
    FormAction = Create;
    passwordRules.push({
      required: true,
      message: t('pages.userForm.fields.password.errors.required')
    });
  }

  const { formProps, saveButtonProps, queryResult } = useForm<Users>({
    metaData: {
      mutation: {
        operation: 'createUser',
        variables: {
          input: {
            type: 'CreateUserInput!'
          }
        }
      },
      fields: userFields
    },
    errorNotification: () => ({
      type: 'error',
      description: t('notifications.error', {
        statusCode: 500
      }),
      message: t('notifications.errorEmailExist', {
        resource: 'User',
        statusCode: 500
      })
    }),
    redirect: 'list'
  });

  const { selectProps: organizationSelectProps } = useSelect<Organizations>({
    resource: 'organizations',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 99999,
    metaData: {
      fields: ['id', 'name']
    },
    sort: [
      {
        field: 'name',
        order: 'asc'
      }
    ],
    onSearch: value => {
      const filters: CrudFilters = [
        {
          field: 'name',
          operator: 'contains',
          value: `%${value}%`
        }
      ];
      return filters;
    }
  });

  formProps.initialValues = {
    organization_id: organizationSelectProps?.options?.find(
      item => item?.value === queryResult?.data?.data?.organization_id
    )?.value,
    // eslint-disable-next-line prettier/prettier, max-len
    role: filteredAssignableRoleOptions?.find(
      item => item?.value === queryResult?.data?.data?.role
    )?.value,
    title: personTitleOptions[0].value,
    first_name: queryResult?.data?.data?.first_name,
    last_name: queryResult?.data?.data?.last_name,
    email: queryResult?.data?.data?.email,
    skype: queryResult?.data?.data?.skype,
    mobile: queryResult?.data?.data?.mobile,
    work_phone: queryResult?.data?.data?.work_phone,
    is_activated: true
  };

  const handleOrganizationChanged = async (organizationId: number) => {
    const res: {
      organizations_by_pk: Organizations;
    } = await graphqlClient.request(GET_ORGANIZATION_BY_ID, {
      organization_id: organizationId
    });
    const selectedOrgDetail: Organizations = res.organizations_by_pk;

    if (selectedOrgDetail.code === 'TESTAG') {
      setFilteredAssignableRoleOptions(assignableRoleOptions);
      return;
    }

    if (!selectedOrgDetail.is_public_agency) {
      setFilteredAssignableRoleOptions(
        assignableRoleOptions.filter(
          item =>
            ![
              UserRole.SUPER_ADMIN,
              UserRole.TRAVEL_CONSULTANT,
              UserRole.TRAVEL_CONSULTANT_MANAGER
            ].includes(item.value as UserRole)
        )
      );
    } else {
      setFilteredAssignableRoleOptions(
        assignableRoleOptions.filter(
          item =>
            ![
              UserRole.TRAVEL_AGENCY_MANAGER,
              UserRole.TRAVEL_AGENCY_SPECIALIST,
              UserRole.TRAVEL_AGENT,
              UserRole.SUPPLIER_MANAGER,
              UserRole.SUPPLIER_EDITOR
            ].includes(item.value as UserRole)
        )
      );
    }
  };

  return (
    <FormAction
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{ extra: <ListButton /> }}
    >
      <Form {...formProps} layout="vertical">
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.userForm.fields.organization.label')}
              name="organization_id"
              rules={[
                {
                  required: true,
                  message: t(
                    'pages.userForm.fields.organization.errors.required'
                  )
                }
              ]}
            >
              <Select
                {...organizationSelectProps}
                onChange={(e: any) => handleOrganizationChanged(e)}
                placeholder={t(
                  'pages.userForm.fields.organization.placeholder'
                )}
              />
            </Form.Item>
          </Col>
          {!!filteredAssignableRoleOptions?.length && (
            <Col span={12}>
              <Form.Item
                label={t('pages.userForm.fields.role.label')}
                name="role"
                rules={[
                  {
                    required: true,
                    message: t('pages.userForm.fields.role.errors.required')
                  }
                ]}
              >
                <Select
                  options={filteredAssignableRoleOptions}
                  placeholder={t('pages.userForm.fields.role.placeholder')}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.userForm.fields.firstName.label')}
              name="first_name"
              rules={[
                {
                  required: true,
                  message: t('pages.userForm.fields.firstName.errors.required')
                }
              ]}
            >
              <Input
                addonBefore={
                  <Form.Item name="title">
                    <Select
                      placeholder={t('pages.userForm.fields.title.placeholder')}
                      options={personTitleOptions}
                      style={{ width: '120px' }}
                    />
                  </Form.Item>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('pages.userForm.fields.lastName.label')}
              name="last_name"
              rules={[
                {
                  required: true,
                  message: t('pages.userForm.fields.lastName.errors.required')
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('pages.userForm.fields.email.label')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('pages.userForm.fields.email.errors.required')
                },
                {
                  type: 'email',
                  message: t('pages.userForm.fields.email.errors.invalid')
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('pages.userForm.fields.password.label')}
              name="password"
              rules={passwordRules}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={8}>
            <Form.Item
              label={t('pages.userForm.fields.skype.label')}
              name="skype"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('pages.userForm.fields.mobile.label')}
              name="mobile"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('pages.userForm.fields.workPhone.label')}
              name="work_phone"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[40, 16]}>
          <Col span={8}>
            <Form.Item
              label="Active"
              name="is_activated"
              valuePropName="checked"
            >
              <Switch defaultChecked={action === 'create'} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </FormAction>
  );
};
